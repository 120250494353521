<template>
  <!--<nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav>-->
  <!--<div id="overlay"></div>-->
  <router-view />
</template>

<style lang="scss">
body {
  margin: 0;
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.5;
  transition: opacity 0.5s ease;
  pointer-events: none;
  /*background-image: url('../public/img/previews/about2.jpg');
  background-size: 100%;
  background-repeat: no-repeat;*/
}
</style>
